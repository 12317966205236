<template>
  <el-card class="application-item">
    <img :src="appData.coverUrl" class="image" />
    <div style="padding: 8px">
      <div class="name">
        {{ appData.name }}
      </div>
      <div class="description">
        上架时间：{{ appData.marketDate.substring(0, 10) }}
      </div>
      <div class="description">
        {{ appData.description }}
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "application-item",
  props: {
    appData: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style lang="less">
.application-item {
  height: 300px;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.03);
    transition: 500ms;
  }

  .image {
    width: 100%;
    display: block;
  }

  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .description {
    color: #6b6b6b;
    overflow: hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    white-space: nowrap;
  }

}
</style>